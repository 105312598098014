<template>
  <div>
    <b-overlay :show="false" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formWO">
        <input type="hidden" name="user_id" :value="user.id">
        <input type="hidden" name="parent_id" :value="parent_order_id">
        <!-- form -->
        <b-row>
          <b-col cols="12">
            <b-form-group label="Titulo" label-for="Titulo">
              <b-form-input v-model="order.title" placeholder="Titulo" name="title" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea name="description" v-model="order.description" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Comentarios" label-for="comments">
              <b-form-textarea name="comments" v-model="order.comments" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
              Crear Orden
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>

  import { BFormTextarea, BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BLink, BImg } from 'bootstrap-vue'  
  import { mapState } from 'vuex';  
  export default {
    components : { BFormTextarea, BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BLink, BImg},
    data() {
      return {
        show : true,
        parent_order : {},
        parent_order_id : this.$route.params.order,
        order : {description : null, comment : null }
      }
    },
    created() {
    this.$http.get("/api/getOrder?id=" + this.parent_order_id).then((response) => {
      this.parent_order = response.data;
      this.order.parent_id = 
      this.show = false
    })
  },
    methods: {
      saveForm : async function (e) {
        this.show = true;
        var formData = new FormData(this.$refs.formWO);
        await this.$http({
          method: "post",
          url: "/api/insertWork",
          data: formData,
        }).then(response => { 
          this.show = false;
          //refirecionar a edit
        })
          .catch(response => { window.console.log(response) });
        }
    },
    computed: {
    ...mapState({
      user: state => state.auth.user,
    })
  },    
  }
</script>

<style>

</style>